import $, { css, get } from "jquery";
import "@fancyapps/fancybox";
import 'ionicons/dist/ionicons.js';
import "./menu";
import Swiper from 'swiper/bundle';
import "magnific-popup/dist/jquery.magnific-popup.min";
import 'select2';
$(function ($) {
    $('.wpcf7-select').select2();
    var heroSlider = new Swiper('.siteSwiper', {
        loop: true,
        autoplay: {
            delay: 5000,
        },
        scrollbar: {
            el: ".swiper-scrollbar",
            hide: false,
        }
    });

    // Function to check window width and add class if less than 1024
    function checkWidth() {
        if ($(window).width() <= 1100) {
            $('body').addClass('is-mobile');
        } else {
            $('body').removeClass('is-mobile');
        }
    }

    // Execute on load
    checkWidth();

    // Bind to window resize event
    $(window).on('resize', function () {
        checkWidth();
    });

    // Custom tabs
    const tabContainer = $('.tab-container');

    tabContainer.on('click', function (e) {
        e.stopPropagation();
        console.log('click');
        const tabNavs = $('.tab-nav button');
        const tabItems = $('.tab-item');

        if ($(e.target).hasClass('tab-btn')) {
            tabNavs.removeClass('active');
            tabNavs.parent().removeClass('parent-active');
            tabItems.addClass('hide');

            let tabId = $(e.target).attr('id');

            tabItems.each(function () {
                if ($(this).data('id') === tabId) {
                    $(e.target).addClass('active');
                    $(e.target).parent().addClass('parent-active');
                    $(this).removeClass('hide');
                }
            });
        }
    });

    $('.search-icon').on('click', function (e) {
        e.stopPropagation();
        $('.search-section').toggle(); // Toggle the visibility of the search form
        $('.close-icon').toggle(); // Toggle the visibility of the close icon
    });

    $('.close-icon').on('click', function (e) {
        e.stopPropagation();
        $('.search-section').hide(); // Hide the search form
        $('.close-icon').hide(); // Hide the close icon
    });

    // $(document).on('click', function(e) {
    //     if (!$(e.target).closest('.search-wrapper').length) {
    //       if ($(e.target).closest('.search-section').length === 0) {
    //         $('.search-form').hide(); // Hide the search form if clicked outside
    //         $('.close-icon').hide(); // Hide the close icon if clicked outside
    //       }
    //     }
    //   });

    $('.gov-ua').magnificPopup({
        delegate: 'a',
        removalDelay: 500, //delay removal by X to allow out-animation
        fixedContentPos: true,
        callbacks: {
          beforeOpen: function() {
             this.st.mainClass = this.st.el.attr('data-effect');
          }
        },
        midClick: true 
      });
});
